import * as React from 'react';
import { IDropDownMenuProps } from '../../../DropDownMenu.types';
import DropDownMenuBase from '../../DropDownMenuBase';
import MenuButton from '../../../../MenuButton/viewer/skinComps/BaseButton/TextSeparatorsMenuButtonNSkin';
import styles from './TextSeparatorsMenuButtonSkin.scss';

const TextSeparatorsMenuButtonSkin: React.FC<IDropDownMenuProps> = props => {
  return <DropDownMenuBase {...props} styles={styles} Button={MenuButton} />;
};

export default TextSeparatorsMenuButtonSkin;
